import React from "react";

import { dayLabels } from "./--constants";

import "./calendar-day-labels.scss";

const CalendarDayLabels = () => {
    const renderDayLabels = () => {
        return dayLabels.map(item => <div key={item} className="calendar-day">{item.substring(0,3)}</div>);
    }

    return <div className="calendar-days">
        {renderDayLabels()}
    </div>
}

export default CalendarDayLabels;