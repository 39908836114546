import React from "react";

import "./index.scss";

export const Dropdown = ({data=[], selected='', onSelect=()=>false}) => {
    const renderDropdownItems = () => {
        return data.map(({label,value})=><div key={value} className={`dropdown-item ${selected===value?'active':''}`} onClick={()=>onSelect(value)}>
            {label}
        </div>)
    }

    return <div className="dropdown-wrap">
        <div className="dropdown-container">
            {renderDropdownItems()}
        </div>
    </div>
}

export default Dropdown;