import { combineReducers } from "redux";
import { update } from "lodash";

import { SessionReducer } from "./session-reducer";
import { SearchEventsReducer } from "./search-events-reducer";
import { ContextMenuReducer } from "./context-menu-reducer";
import { CalendarReducer } from "./calendar-reducer";

import { COMMON } from "../types";

const allReducers = combineReducers({
    SessionReducer,
    ContextMenuReducer,
    SearchEventsReducer,
    CalendarReducer
});

const rootReducer = (state, action) => {
    let newState = { ...state };
    switch (action.type) {
        case COMMON.RESET_FIELD:
            if (action.key) {
                newState = update(state, action.key, () => action.value);
            }
            return {
                ...newState
            };
        default:
        // do nothing;
    }
    return allReducers(newState, action);
};

export default rootReducer;
