import { CONTEXT_MENU } from "../types";

const initialState = { 
    contextMenu: {x:0, y:0, data: null},
    showContextMenu: false
}

export const ContextMenuReducer = (state = {...initialState}, action) => {
    switch (action.type) {
        case CONTEXT_MENU.SET_CONTEXT_MENU:
            return {
                ...state,
                contextMenu: action.payload
            };
        case CONTEXT_MENU.TOGGLE_CONTEXT_MENU:
            return {
                ...state,
                showContextMenu: action.payload
            };
        default:
            return state;
    }
};
