import { SEARCH_EVENTS } from "../types";

export const SearchEventsReducer = (state = { search: '', calendarEvents: {} }, action) => {
    switch (action.type) {
        case SEARCH_EVENTS.SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case SEARCH_EVENTS.CALENDAR_EVENTS: 
            const {events, birthdays, year, month} = action.payload;
            return {
                ...state,
                calendarEvents: (events) ? {
                    year: Number(year),
                    month: month-1,
                    events: events.map(event => {
                        const t = event.datetime.split(/[- :]/);
                        const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
                        return {...event, datetime: d, duration: Number(event.duration), poc_number: Number(event.poc_number)};
                    }),
                    birthdays
                } : {}
            }
        case SEARCH_EVENTS.SAMPLE_CALENDAR_EVENTS: 
            const {events: events2, year: year2, month: month2} = action.payload;
            return {
                ...state,
                sampleCalendarEvents: (events2) ? {
                    year: Number(year2),
                    month: month2-1,
                    events: events2.map(event => {
                        const t = event.datetime.split(/[- :]/);
                        const d = new Date(Date.UTC(t[0], month2-1, t[2], t[3], t[4], t[5]));
                        return {...event, datetime: d, duration: Number(event.duration), poc_number: Number(event.poc_number)};
                    })
                } : {}
            }
        default:
            return state;
    }
};
