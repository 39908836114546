import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import "./index.scss";

export const Highlighter = ({data, type="javascript"}) => {
	return <SyntaxHighlighter showLineNumbers={true} wrapLongLines={true} lineNumberStyle={{color:'rgba(0,0,0,0.15)'}} className="code-highlighter" language={type} style={vs}>{data}</SyntaxHighlighter>;
}

export default Highlighter;
