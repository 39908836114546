export const COMMON = {
    RESET_FIELD: 'RESET_FIELD'
};

export const CONTEXT_MENU = {
    SET_CONTEXT_MENU: 'SET_CONTEXT_MENU',
    TOGGLE_CONTEXT_MENU: 'TOGGLE_CONTEXT_MENU'
}

export const SESSION = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    AUTHENTICATE: 'AUTHENTICATE'
}

export const SEARCH_EVENTS = {
    SEARCH: 'SEARCH',
    CALENDAR_EVENTS: 'CALENDAR_EVENTS',
    SAMPLE_CALENDAR_EVENTS: 'SAMPLE_CALENDAR_EVENTS',
    DELETE_CALENDAR_EVENTS: 'DELETE_CALENDAR_EVENTS'
}

export const CALENDAR = {
    CURRENT_YEAR: 'CURRENT_YEAR',
    CURRENT_MONTH: 'CURRENT_MONTH',
    CURRENT_MONTH_DAYS: 'CURRENT_MONTH_DAYS',
    CURRENT_MONTH_START_DAY: 'CURRENT_MONTH_START_DAY',
    SELECTED_DATE: 'SELECTED_DATE',
    EVENT_PANEL_DATA: 'EVENT_PANEL_DATA',
    BIRTHDAY_PANEL_DATA: 'BIRTHDAY_PANEL_DATA',
    TIMELOG_PANEL_DATA: 'TIMELOG_PANEL_DATA',
    ADD_EDIT_EVENTS: 'ADD_EDIT_EVENTS',
    ADD_EDIT_BIRTHDAY: 'ADD_EDIT_BIRTHDAY',
    ADD_EDIT_TIMELOG: 'ADD_EDIT_TIMELOG',
    MOBILE_DAY_PANEL: 'MOBILE_DAY_PANEL',
    CALENDAR_FILTER: 'CALENDAR_FILTER'
}