import { SEARCH_EVENTS } from "../types";
import { fetchHandler } from "../fetch-handler";

export const searchEvents = (payload) => {
    return {
        type: SEARCH_EVENTS.SEARCH,
        payload
    }
}

export const clearCalendarEvents = () => {
    return {
        type: SEARCH_EVENTS.CALENDAR_EVENTS,
        payload: {}
    }
}

export const fetchCalendarEvents = (currentYear, currentMonth) => {
    const fetchOptions = {
        url:`events/listing.php?year=${currentYear}&month=${currentMonth+1}`,
        method: "GET",
        actionType: SEARCH_EVENTS.CALENDAR_EVENTS
    };
    return fetchHandler(fetchOptions);
}

export const fetchSampleCalendarEvents = (currentYear, currentMonth) => {
    const fetchOptions = {
        secure: false,
        url:`events/listing-sample.php?year=${currentYear}&month=${currentMonth+1}`,
        method: "GET",
        actionType: SEARCH_EVENTS.SAMPLE_CALENDAR_EVENTS
    };
    return fetchHandler(fetchOptions);
}

export const deleteCalendarEvents = ({guid,type=''}, successHandler) => {
    const fetchOptions = {
        url: type === "BIRTHDAY" ? `birthdays/delete.php?guid=${guid}` : `events/delete.php?guid=${guid}`,
        method: "GET",
        actionType: SEARCH_EVENTS.DELETE_CALENDAR_EVENTS
    };
    return fetchHandler(fetchOptions, successHandler);
}