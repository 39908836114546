import { rootURL, cookieVariable } from "../constant/env";
import { getCookie } from "../utils";

export function fetchHandler(
    {
        url,
        method = "GET",
        actionType,
        headers,
        body,
        secure = true,
        fileUpload = false
    },
    successHandler = null,
    errorHandler = null
) {
    return (dispatch) => {
        // setup AbortController
        const controller = new AbortController();

        const triggerSuccessHandler = response => {
            dispatch({
                type: actionType,
                payload: response
            });
            return successHandler ? successHandler(response) : null;
        };

        const accessToken = getCookie(cookieVariable);

        let headersData = {
            ...headers,
            Accept: fileUpload ? "multipart/form-data" : "application/json",
            "Content-Type": fileUpload
                ? "multipart/form-data"
                : "application/json"
        };

        if (secure) {
            headersData = {
                ...headersData,
                Authorization: `Bearer ${accessToken}`
            };
        }

        const request = fetch(`${rootURL}${url}`, {
            signal: controller.signal,
            method,
            headers: {
                ...headersData
            },
            body
        });

        let status = null;
        request
            .then(data => {
                status = data.status;
                return data.json();
            })
            .then(response => {
                if (status && status > 399) {
                    return errorHandler ? errorHandler(response) : null;
                } else {
                    return triggerSuccessHandler(response);
                }
            })
            .catch(err => {
                const errorObj = {
                    error: {
                        url: `${rootURL}${url}`,
                        code: "FETCH_FAILED",
                        message: err
                    }
                };
                return errorHandler ? errorHandler(errorObj) : null;
            });

        return {
            type: actionType,
            payload: request
        };
    };
}
