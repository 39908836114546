import { CALENDAR } from "../types";
import { fetchHandler } from "../fetch-handler";

export const setCurrentYear = (payload) => {
    return {
        type: CALENDAR.CURRENT_YEAR,
        payload
    }
}

export const setCurrentMonth = (payload) => {
    return {
        type: CALENDAR.CURRENT_MONTH,
        payload
    }
}

export const setCurrentMonthDays = (payload) => {
    return {
        type: CALENDAR.CURRENT_MONTH_DAYS,
        payload
    }
}

export const setCurrentMonthStartDay = (payload) => {
    return {
        type: CALENDAR.CURRENT_MONTH_START_DAY,
        payload
    }
}

export const setSelectedDate = (payload) => {
    return {
        type: CALENDAR.SELECTED_DATE,
        payload
    }
}

export const setEventPanelData = (payload) => {
    return {
        type: CALENDAR.EVENT_PANEL_DATA,
        payload
    }
}

export const setBirthdayPanelData = (payload) => {
    return {
        type: CALENDAR.BIRTHDAY_PANEL_DATA,
        payload
    }
}

export const setTimelogPanelData = (payload) => {
    return {
        type: CALENDAR.TIMELOG_PANEL_DATA,
        payload
    }
}

export const addEditEvent = (values, successHandler) => {
    const fetchOptions = {
        url:`events/add.php`,
        method: "POST",
        body: JSON.stringify(values),
        actionType: CALENDAR.ADD_EDIT_EVENTS
    };
    return fetchHandler(fetchOptions, successHandler);
}

export const addEditBirthday = (values, successHandler) => {
    const fetchOptions = {
        url:`birthdays/add.php`,
        method: "POST",
        body: JSON.stringify(values),
        actionType: CALENDAR.ADD_EDIT_BIRTHDAY
    };
    return fetchHandler(fetchOptions, successHandler);
}

export const addEditTimelog = (values, successHandler) => {
    const fetchOptions = {
        url:`timelogs/add.php`,
        method: "POST",
        body: JSON.stringify(values),
        actionType: CALENDAR.ADD_EDIT_TIMELOG
    };
    return fetchHandler(fetchOptions, successHandler);
}

export const setMobileDayPanel = (payload) => {
    return {
        type: CALENDAR.MOBILE_DAY_PANEL,
        payload
    }
}

export const setCalendarFilter = (payload) => {
    return {
        type: CALENDAR.CALENDAR_FILTER,
        payload
    }
}