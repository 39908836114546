import { CONTEXT_MENU } from "../types";

export const setContextMenu = (x,y,data) => {
    return {
        type: CONTEXT_MENU.SET_CONTEXT_MENU,
        payload: { x,y,data }
    }
}

export const toggleContextMenu = (payload) => {
    return {
        type: CONTEXT_MENU.TOGGLE_CONTEXT_MENU,
        payload
    }
}

