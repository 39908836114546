export { Image } from "./image";
export { SlideShow } from "./slide-show";
export { Checkbox } from "./custom-checkbox";
export { Highlighter } from "./syntax-highlighter";
export { FormGroup } from "./form-group";
export { TabNav } from "./tab-nav";
export { Dropdown } from "./dropdown";

export const setCookie = (cname, cvalue, exdays=1) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const deleteCookie = (cname, exdays=1) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = `${cname}=;${expires};path=/`;
}

export const renderUSDatetime = (d) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return d.toLocaleString("en-US", options);
}

export const renderUSDate = (d) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    return d.toLocaleString("en-US", options);
}

export const nToBr = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}