import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import App from './testapp';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { ContextProvider } from "./context";
import { store } from "./store";

ReactDOM.render(
	<React.StrictMode>
		<ContextProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</ContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
