import React, { useState } from "react";

import "./index.scss";

export const Image = ({ src, onLoad = () => {}, title = "Alternative text" }) => {
    const [loader, setLoader] = useState(true);
    const onImageLoad = () => {
        setLoader(false);
        onLoad();
    }
    return <div className={`image-container ${loader ? 'loading' : 'loaded'}`}>
        <div className="image-loader" />
        <img src={src} onLoad={onImageLoad} alt={title} />
    </div>
}

export default Image;