import React, { useReducer, useEffect, useRef, useState } from "react";

import CalendarHead from "./calendar-head";
import CalendarDates from "./calendar-dates";
import CalendarMonths from "./calendar-months";
import CalendarYears from "./calendar-years";

import { displayTypes, pickerTypes } from "./--constants";

import "./calendar.scss";

const initialState = value => {
    const d = new Date(value);
    return {
        calendarType: pickerTypes.date,
        currentDate: d.getDate(), 
        currentMonth: d.getMonth(),
        currentYear: d.getFullYear(), 
        currentMonthDays: new Date(d.getFullYear(), d.getMonth()+1, 0).getDate(),
        currentMonthStartDay: new Date(d.getFullYear(), d.getMonth(), 1).getDay(), 
    }
}

const reducer = (state, payload) => {
	return {...state, ...payload};
}

const Calendar = ({
    trigger,
    display = displayTypes.dropdown, 
    type = pickerTypes.date, 
    value = new Date(),
    onSelect = () => {}, 
    onCancel = () => {}, 
    autoClose = false,
    showSelected = false,
    renderContent = ()=>null,
    onDayContextMenu = () => false,
    onMonthChange = () => {},
    onYearChange = () => {},
    prependHeaderItem = () => null,
    appendHeaderItem = () => null,
}) => {
    const [{ calendarType, currentYear, currentMonth, currentMonthDays, currentMonthStartDay, currentDate }, dispatch] = useReducer(reducer,initialState(value));

    const ref = useRef();

    const [pos,setPos] = useState({x:0,y:0});

    useEffect(() => {
        if(display !== displayTypes.inline) {
            const pos = trigger?.current?.getBoundingClientRect();
            setPos({y: (pos.top+pos.height), x: pos.left});
        }
    }, [trigger, display]);

    useEffect(() => {
        dispatch({currentMonthDays: new Date(currentYear, currentMonth+1, 0).getDate()});
        dispatch({currentMonthStartDay: new Date(currentYear, currentMonth, 1).getDay()});
    }, [currentYear, currentMonth]);

    useEffect(() => {
        if(currentDate > currentMonthDays) {
            dispatch({currentDate: currentMonthDays})
        }
    }, [currentDate, currentMonthDays])

    useEffect(() => {
        dispatch({calendarType: type})
    }, [type])

    const calendarHeadProps = {
        type,
        autoClose,
        calendarType,
        currentYear,
        currentMonth,
        setCurrentMonth: currentMonth => {
            onMonthChange(currentMonth)
            dispatch({currentMonth});
        },
        setCurrentYear: currentYear => {
            onYearChange(currentYear)
            dispatch({currentYear});
        },
        onMonthPanel: () => calendarType !== pickerTypes.month ? dispatch({calendarType: pickerTypes.month}) : false,
        onYearPanel: () => calendarType !== pickerTypes.year ? dispatch({calendarType: pickerTypes.year}) : false,
        prependComp: prependHeaderItem,
        appendComp: appendHeaderItem,
        onCancel: () => onCancel(value),
        onOk: () => onSelect(new Date(currentYear, currentMonth, currentDate))
    }

    const calendarDatesProps = {
        currentYear,
        currentMonth,
        currentMonthDays,
        currentMonthStartDay,
        currentDate,
        setCurrentMonthDays: currentMonthDays=>dispatch({currentMonthDays}),
        setCurrentMonthStartDay: currentMonthStartDay=>dispatch({currentMonthStartDay}),
        onSelect: currentDate=> {
            if(autoClose && type === pickerTypes.date) onSelect(new Date(currentYear, currentMonth, currentDate));
            dispatch({currentDate});
        },
        renderContent,
        onDayContextMenu
    }

    const calendarMonthsProps = {
        currentMonth,
        currentYear,
        onSelect: currentMonth => {
            if(autoClose && type === pickerTypes.month) onSelect(new Date(currentYear, currentMonth, currentDate));
            dispatch({currentMonth, calendarType: type === pickerTypes.date ? pickerTypes.date : pickerTypes.month});
            onMonthChange(currentMonth);
        }
    }

    const calendarYearsProps = {
        currentYear,
        onSelect: currentYear => {
            if(autoClose && type === pickerTypes.year) onSelect(new Date(currentYear, currentMonth, currentDate));
            dispatch({currentYear, calendarType: type !== pickerTypes.year ? pickerTypes.month : pickerTypes.year});
            onYearChange(currentYear);
        }
    }

    const renderCalendarBody = () => {
        switch(calendarType) {
            case pickerTypes.month :
                return <CalendarMonths {...calendarMonthsProps} />;
            case pickerTypes.year :
                return <CalendarYears {...calendarYearsProps} />;
            default :
                return <CalendarDates {...calendarDatesProps} />
        }
    }

    return <>
        <div className="calendar-overlay" onClick={()=>onCancel(value)} />
        <div className={`calendar-wrap ${displayTypes[display]}`} style={{left: pos.x+'px', top: pos.y+'px'}} ref={ref}>
            <div className="calendar-container">
                <CalendarHead {...calendarHeadProps} />
                {showSelected && display !== displayTypes.inline ? <div className="selected-date-value">{new Date(currentYear, currentMonth, currentDate).toDateString()}</div> : null}
                <div className="calendar-body">
                    {renderCalendarBody()}
                </div>
            </div>
        </div>
    </>
}

export default Calendar;