import React from "react";

import "./index.scss";

export const TabNav = ({selected='', data=[], onSelect=()=>false}) => {
    return <ul className="tab-nav">
        {data.map(i=><li key={i.value} className={`nav-item ${i.value===selected?'selected':''}`} onClick={()=>onSelect(i.value)}>{i.label}</li>)}
    </ul>
}

export default TabNav;