import { SESSION } from "../types";
import { fetchHandler } from "../fetch-handler";

export const actionLogin = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        url:`session/signin.php`,
        method: "POST",
        secure: false,
        body: JSON.stringify(values),
        actionType: SESSION.LOGIN
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export const actionLogout = () => {
    return {
        type: SESSION.LOGOUT
    }
}

export const authenticateUser = (token, successHandler, errorHandler) => {
    if(token) {
        const fetchOptions = {
            url:`session/authenticate.php`,
            method: "POST",
            body: JSON.stringify({token}),
            actionType: SESSION.AUTHENTICATE
        };
        return fetchHandler(fetchOptions, successHandler, errorHandler);
    } else {
        return {
            type: SESSION.LOGOUT,
            payload: token
        }
    }
}