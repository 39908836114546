import React from "react";
import "./index.scss";

export const Checkbox = ({ name, label, onChange, value }) => {
    return <label className="custom-checkbox">
        <input 
            type="checkbox" 
            name={name} 
            checked={value} 
            onChange={val => onChange(val)}
        />
        <span className="custom-checkbox-label">{label}</span>
    </label>
}

export default Checkbox;

