import { SESSION } from "../types";
import { setCookie, deleteCookie } from "../../utils";
import { cookieVariable } from "../../constant/env";

export const SessionReducer = (state = { userDetails: null, authenticating: true }, action) => {
    switch (action.type) {
        case SESSION.LOGIN:
            const {result=null,token=null} = action.payload;
            setCookie(cookieVariable, token);
            return {
                ...state,
                userDetails: result,
                authenticating: false
            };
        case SESSION.LOGOUT:
            deleteCookie(cookieVariable);
            return {
                ...state,
                userDetails: null,
                authenticating: false
            };
        case SESSION.AUTHENTICATE:
            return {
                ...state,
                userDetails: action?.payload?.result,
                authenticating: false
            };
        default:
            return state;
    }
};
