import { lazy } from 'react';

export { Works } from "./works";

export const Home = lazy(() => import("./page-home"));
export const PageLogin = lazy(() => import("./page-login"));
export const Work = lazy(() => import("./page-work"));
export const Calendar = lazy(() => import("./page-calendar"));
export const Dashboard = lazy(() => import("./page-dashboard"));
export const PageAppCalendar = lazy(() => import("./page-app-calendar"));
export const PageAppTimePicker = lazy(() => import("./page-app-timepicker"));