import React from "react";

import { monthLabels } from "./--constants";

import "./calendar-months.scss";

const CalendarMonths = ({onSelect = () => false}) => {
    const renderMonthLabels = () => {
        return monthLabels.map((item, index) => <div key={item} className="calendar-month" onClick={()=>onSelect(index)}>{item.substring(0,3)}</div>);
    }

    return <div className="calendar-months">
        {renderMonthLabels()}
    </div>
}

export default CalendarMonths;