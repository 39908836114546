import React from "react";

import CalendarDayLabels from "./calendar-day-labels";
import { dayLabels, handleCurrentDateClassName } from "./--constants";

import "./calendar-dates.scss";

const CalendarDates = ({currentYear, currentMonth, currentDate, onSelect, currentMonthDays = 0, currentMonthStartDay, renderContent=()=>null, onDayContextMenu=()=>false}) => {
    const renderSpaceStart = () => {
        const emptyDays = [...new Array(currentMonthStartDay)];
        return emptyDays.map((item,index) => <div className={`calendar-date empty ${index%7===0?'sunday':''}`} key={index} />);
    }

    const checkCurrentDate = event => {
        return event?.date === currentDate ? 'selected-date' : '';
    }

    const renderMonthDays = () => {
        if(currentMonthDays) {
            const dateList = [...new Array(currentMonthDays)].map((e,i) => ({date: i+1, dateFull: new Date(currentYear, currentMonth, i+1)}));
            return dateList.map((item,index) => <div 
                key={item.date} 
                className={`calendar-date ${dayLabels[(index+currentMonthStartDay)%7].toLowerCase()} ${handleCurrentDateClassName(item)} ${checkCurrentDate(item)}`}
                data-date={item.dateFull}
                onContextMenu={e=>onDayContextMenu(e, item)}>
                <div onClick={()=>onSelect(item.date)} className="calendar-date-lebel">{item.date}</div>
                {renderContent(item.dateFull)}
            </div>);
        }
        return null;
    }

    const renderSpaceEnd = () => {
        const days = currentMonthDays + currentMonthStartDay;
        const monthWeeks = Math.ceil(days / 7);
        const totalWeekDays = monthWeeks * 7;
        const remainingDays = [...new Array(totalWeekDays - days)];
        return remainingDays.map((item,index) => <div className="calendar-date empty" key={index} />);
    }

    const daysLength = Math.ceil((currentMonthStartDay + currentMonthDays)/7);

    return <>
        <CalendarDayLabels />
        <div className={`calendar-dates month-rows-${daysLength}`}>
            {renderSpaceStart()}
            {renderMonthDays()}
            {renderSpaceEnd()}
        </div>
    </>
}

export default CalendarDates;