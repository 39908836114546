import React, { useContext, useState } from 'react';

import { fetchHandler } from "./fetch-handler";
import { states } from "./states";

export const DataContext = React.createContext(states);

export const ContextProvider = ({children}) => {
    const origin = window.location?.origin;
    
    const context = useContext(DataContext);
    const [state, setState] = useState(context);

    const setStateFn = (label, value) => {
        if(!state[label] || JSON.stringify(state[label]) !== JSON.stringify(value)) {
            setState({...state, [label]: value});
        }
    }

    const dispatch = ({label=null, value=null, url=null}) => {
        if(url && label && !value) {
            return fetch(`${origin}/${url}`, {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(response => {
                return response.json();
            }).then(body => {
                return setStateFn(label, body.data);
            });
        } else {
            return setStateFn(label, value);
        }
    }

    return <DataContext.Provider value={{...state, dispatch}}>
        {children}
    </DataContext.Provider>
}

const useStore = () => useContext(DataContext);

export { useStore, fetchHandler };