import React from "react";
import "./index.scss";

const Loader = ({className=""}) => {
    return (
        <div className={`loader-container ${className}`}>
            <div className="loader" />
        </div>
    )
}

export default Loader;