import React from "react";
import { monthLabels, pickerTypes } from "./--constants";

import "./calendar-head.scss";

const CalendarHead = ({type = pickerTypes.date, autoClose = false, calendarType = pickerTypes.date, prependComp = () => {}, appendComp = () => {}, currentYear, setCurrentYear, currentMonth, setCurrentMonth, onMonthPanel, onYearPanel, onCancel = () => false, onOk = () => false}) => {
    const updateMonth = val => {
        if(val < 0) {
            setCurrentYear(currentYear-1);
            return 11;
        } else if(val > 11) {
            setCurrentYear(currentYear+1);
            return 0;
        } else {
            return val;
        }
    }
    const onMonthPrev = () => {
        setCurrentMonth(updateMonth(currentMonth-1))
    }
    const onMonthNext = () => {
        setCurrentMonth(updateMonth(currentMonth+1))
    }

    return <div className="calendar-head">
        {prependComp()}
        {calendarType === pickerTypes.date ? <>
            <button type="button" className="calendar-head-nav-prev" tabIndex="1" onClick={onMonthPrev}><span className="ico icon-chevron-left" /></button>
            <button type="button" className="calendar-head-nav-next" tabIndex="2" onClick={onMonthNext}><span className="ico icon-chevron-right" /></button>
        </> : null}
        {type !== pickerTypes.year ? <button type="button" className="calendar-head-month" tabIndex="3" onClick={()=>onMonthPanel(currentMonth)}>{monthLabels[currentMonth]}</button> : null}
        <button type="button" className="calendar-head-year" tabIndex="4" onClick={()=>onYearPanel(currentYear)}>{currentYear}</button>
        <div className="calendar-head-nav-spacer" />
        {appendComp()}
        {autoClose ? null : <>
            <button type="button" className="calendar-head-cancel" tabIndex="5" onClick={()=>onCancel()}><span className="ico icon-x" /></button>
            <button type="button" className="calendar-head-ok" tabIndex="6" onClick={()=>onOk()}><span className="ico icon-check" /></button>
        </>}
    </div>
}

export default CalendarHead;