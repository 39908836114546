import React from "react";

import Select from "./select";
import AppCalendar from "../../components/app-calendar";

export const FormGroup = ({type = 'text', className = '', label = 'Label', value, onFocus, onChange, tabIndex = 1, options = []}) => {
    const defaultProps = {
        value,
        onChange,
        tabIndex,
        className: 'form-control',
        onFocus,
        options
    }

    const renderInput = () => {
        switch(type) {
            case 'text' :
            case 'password' :
            case 'number' :
            case 'time' :
            case 'datetime-local' :
                return renderFormGroup(<input type={type} {...defaultProps} />)
            case 'date' :
                return renderFormGroup(<AppCalendar {...defaultProps} onDateChange={value=>onChange({target:{value}})} type="date" />)
            case 'select' :
                return renderFormGroup(<Select {...defaultProps} />, false)
            case 'textarea' : 
                return renderFormGroup(<textarea {...defaultProps}></textarea>)
            default : return null;
        }
    }

    const renderFormGroup = (children, renderLabel= true) => {
        const renderInner = () => <>
            <div className="form-label">{label}</div>
            {children}
        </>;
        return <div className={`form-group ${className}`}>
            {renderLabel ? <label className="form-item">{renderInner()}</label> : <div className="form-item">{renderInner()}</div>}
        </div>
    }

    return renderInput();
}

export default FormGroup;