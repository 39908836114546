import React, { useEffect, useState } from "react";

import "./calendar-years.scss";

const CalendarYears = ({currentYear = 2022, onSelect = () => false}) => {
    const [year, setYear] = useState(2022);
    
    useEffect(() => {
        setYear(currentYear);
    }, [currentYear]);

    const renderYearLabels = () => {
        const floorYear = (Math.floor(year/23)*23)-10;
        const yearLabels = [...new Array(23)].map((i,index)=>(floorYear+index));
        return yearLabels.map(item => <div key={item} className="calendar-year" onClick={()=>onSelect(item)}>{item}</div>);
    }

    return <div className="calendar-years">
        <div className="calendar-year-nav-prev" onClick={()=>setYear(year-23)}><span className="ico icon-chevron-left" /></div>
        {renderYearLabels()}
        <div className="calendar-year-nav-next" onClick={()=>setYear(year+23)}><span className="ico icon-chevron-right" /></div>
    </div>
}

export default CalendarYears;