import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../dropdown";

export const Select = ({options=[], value, onChange, className, creatable=false}) => {
    const ref = useRef();
    const [selected, setSelected] = useState(options.find(i=>i.value===value)?.label);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const listener = e => {
            if(ref && ref?.current && !ref?.current.contains(e.target)) {
                setOpen(false);
            }
        }
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        }
    }, []);

    const handleSelection = val => {
        setSelected(options.find(i=>i.value===val)?.label);
        setOpen(false);
        onChange({target: {value: val}});
    }

    const handleChange = e => {
        if(creatable) {
            handleSelection(e.target.value);
        } else {
            return false;
        }
    }

    return <div className="select-wrap" ref={ref}>
        <input className={className} type="text" value={selected} onChange={handleChange} onFocus={()=>setOpen(true)} />
        <span className={`select-icon ico icon-chevron-${open ? 'up' : 'down'}`} />
        {open ? <Dropdown data={options} selected={selected} onSelect={handleSelection} /> : null}
    </div>
}

export default Select;