import React, { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
// import { useStore } from "./context";
import { renderThumb } from "./constant/common";
import { cookieVariable } from "./constant/env";

import './App.scss';

import { authenticateUser } from "./store/action";
import { getCookie } from "./utils";
import Loader from "./utils/loader";
import CheckEvent from "./modules/check-event";

import { Home, PageLogin, Calendar, Dashboard, PageAppCalendar, PageAppTimePicker } from "./components";

const App = ({ location, authenticating, authenticateUser, userDetails }) => {
    // const {dispatch, worksRoutes} = useStore();

	useEffect(() => {
		const userToken = getCookie(cookieVariable);
		authenticateUser(userToken?userToken:null);
	}, [authenticateUser]);

    // useEffect(() => {
    //     (!worksRoutes || !worksRoutes.length) && dispatch({label:'worksRoutes', url:'api/worksRoutes.json'});
    // }, [dispatch, worksRoutes]);

	const loader = <Loader className="router-loader" />;

	const renderRouter = (props, Component) => {
		return <Component {...props} />;
	};

	return authenticating ? loader : (
		<BrowserRouter basename={"/"}>
			<Scrollbars 
				className="vijee-main-wrap"
				renderThumbVertical={renderThumb}
			>
				<Suspense fallback={loader}>
					<Switch location={location}>
						<Route
							exact
							path="/"
							component={(props) => renderRouter(props, Home)}
						/>
						<Route
							path="/login"
							component={(props) => renderRouter(props, PageLogin)}
						/>
						<Route
							path="/apps"
							component={(props) => renderRouter(props, Dashboard)}
						/>
						<Route
							path="/app-calendar"
							component={(props) => renderRouter(props, PageAppCalendar)}
						/>
						<Route
							path="/app-timepicker"
							component={(props) => renderRouter(props, PageAppTimePicker)}
						/>
						{userDetails?.guid ? <>
							<Route
								path="/calendar"
								component={(props) => renderRouter(props, Calendar)}
							/>
						</> : null}
						{/* {worksRoutes && worksRoutes.length ? worksRoutes.map(page => {
							return <Route
								key={page.id}
								path={page.route}
								component={(props) => renderRouter(props, Work)}
							/>
						}) : null} */}
						<Redirect to="/" />
					</Switch>
				</Suspense>
				<CheckEvent />
			</Scrollbars>
		</BrowserRouter>
	);
}

const stateToProps = ({SessionReducer}) => {
    return {
        userDetails: SessionReducer?.userDetails,
        authenticating: SessionReducer?.authenticating,
    }
}

const actionToProps = {
    authenticateUser
}

export default connect(stateToProps, actionToProps)(App);
