import React, { useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import Calendar from "./calendar";

import { displayTypes, pickerTypes } from "./--constants";

import "./index.scss";

const modalRoot = document.body;

const Modal = (calendarProps={}) => {
    const el = document.createElement('div');
    el.className = 'calendar-modal-wrap';

    useEffect(() => {
        if(el && modalRoot) {
            modalRoot.appendChild(el);
            return () => {
                modalRoot.removeChild(el);
            }
        }
    }, [el]);

    return ReactDOM.createPortal(<Calendar {...calendarProps} />, el);
}

const AppCalendar = ({
    children = null,
    className="",
    show,
    value, 
    display = displayTypes.dropdown, 
    type = pickerTypes.date, 
    renderContent = ()=>null, 
    onDateChange = () => {},
    onMonthChange = () => {}, 
    onYearChange = () => {},
    prependHeaderItem = () => null,
    appendHeaderItem = () => null,
    onDayContextMenu = () => false,
    onSelect = () => false,
    onCancel = () => false
}) => {
    const ref = useRef();

    const modalProps = {
        trigger: ref,
        onSelect,
        onCancel,
        value,
        autoClose: true,
        showSelected: true,
        display, 
        type,
        renderContent,
        onMonthChange,
        onYearChange,
        prependHeaderItem,
        appendHeaderItem,
        onDayContextMenu,
    }

    return display === displayTypes.inline ? <Calendar {...modalProps} /> : <>
        <div ref={ref} className={`form-group form-group-calendar ${className}`}>
            {children}
        </div>
        {show ? <Modal {...modalProps} /> : null}
    </>
}

export default AppCalendar;