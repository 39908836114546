import React, { useEffect, useState } from "react";

import { monthLabels } from "../../constant/common";
import { notifyMe } from "./helper";

import "./index.scss";

const calendarEvents = [];

const CheckEvent = () => {
    const [open, setOpen] = useState(false);
    const [birthdays, setBirthdays] = useState([]);
    useEffect(() => {
        if(calendarEvents && calendarEvents.length) {
            const nextTime = 1000*60*60*12;
            const nextDatetime = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getTime() - nextTime;
            const upcomingEvents = calendarEvents.filter(i => {
                const time = new Date(new Date().getFullYear(),i.month-1,i.date).getTime();
                return time > nextDatetime;
            });
            if(upcomingEvents.length) {
                setOpen(true);
                setBirthdays(upcomingEvents);
                // Sending browser notification
                const message = upcomingEvents.map(i=>`${i.name} (${i.date} ${monthLabels[i.month-1]} ${i.year})`);
                const title = 'Birthdays';
                notifyMe(title, message.join('\n'));
            }            
        }
    }, []);
    
    const renderEvent = () => {
        return birthdays.map(i => {
            return <div key={i.guid}>
                <h4>{i.name}</h4>
                <div>{i.date} {monthLabels[i.month-1]} {i.year}</div>
            </div>
        })
    }

    const renderEventWrap = () => {
        if(open && birthdays && birthdays.length) {
            return <div className="event-notification-wrap">
                <div className="event-notification-close" onClick={()=>setOpen(false)}><span className="ico icon-x" /></div>
                <div className="event-notification-container">
                    {renderEvent()}
                </div>
            </div>
        } else {
            return <></>;
        }
    }

    return renderEventWrap();
}

export default CheckEvent;