import { CALENDAR } from "../types";

const initialState = {
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth(),
    currentMonthDays: new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate(),
    currentMonthStartDay: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay(),
    selectedDate: new Date().getDate(),
    mobileDayPanel: false
};

export const CalendarReducer = (state = {...initialState}, action) => {
    switch (action.type) {
        case CALENDAR.CURRENT_YEAR:
            return {
                ...state,
                currentYear: action.payload
            };
        case CALENDAR.CURRENT_MONTH: 
            return {
                ...state,
                currentMonth: action.payload
            }
        case CALENDAR.CURRENT_MONTH_DAYS: 
            return {
                ...state,
                currentMonthDays: action.payload
            }
        case CALENDAR.CURRENT_MONTH_START_DAY: 
            return {
                ...state,
                currentMonthStartDay: action.payload
            }
        case CALENDAR.SELECTED_DATE: 
            return {
                ...state,
                selectedDate: action.payload
            }
        case CALENDAR.EVENT_PANEL_DATA:
            return {
                ...state,
                eventPanelData: action.payload
            };
        case CALENDAR.BIRTHDAY_PANEL_DATA: 
            return {
                ...state,
                birthdayPanelData: action.payload
            }
        case CALENDAR.TIMELOG_PANEL_DATA: 
            return {
                ...state,
                timelogPanelData: action.payload
            }
        case CALENDAR.MOBILE_DAY_PANEL: 
            return {
                ...state,
                mobileDayPanel: action.payload
            }
        case CALENDAR.CALENDAR_FILTER: 
            return {
                ...state,
                calendarFilter: action.payload
            }
        default:
            return state;
    }
};
